/* eslint-disable no-restricted-globals */
import React, { useRef, useState } from "react";

import  {ZoomMtg}  from '@zoomus/websdk'
import { useEffect } from "react";
import axios from "./axiosInstance";
import { getAttendance } from "./controllers/attendance";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.18.2/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

function App() {
  const query = new URLSearchParams(location.search); 
  useEffect(async() => {
    const signature = query.get("sign");
    const leaveUrl = query.get("path");
    const userName = query.get("name");
    const type = query.get("type");
    const passWord = query.get('pass');
  
    const data = JSON.parse(atob(signature.split(".")[1]));
    startMeeting(signature, data.mn, userName, data.sdkKey, leaveUrl+`?meetingNumber=${data.mn}&email=${userName.split('-')[1]}`, passWord);
  }, []);

  function startMeeting(signature, meetingNumber, userName, sdkKey, leaveUrl, passWord) {
   
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      disableInvite: true,
      leaveUrl,
      success: (success) => {
        ZoomMtg.join({
          signature,
          meetingNumber,
          userName,
          sdkKey,
          passWord,
          success: (success) => {
            console.log(success);
            ZoomMtg.showRecordFunction({ show: true });
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  return (
    <>
      {axios && (
        <div className="App">
          <main>
            <h1>Something Went Wrong !</h1>
          </main>
        </div>
      )}
    </>
  );
}

export default App;